import { createStyles, makeStyles } from '#/utils/theme';

export const useCampaignInstanceHeaderButtonsStyles = makeStyles((theme) =>
  createStyles({
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.custom.REM_SPACES[1],
    },
    menu: {
      marginTop: theme.custom.REM_SPACES[0.5],
      borderRadius: '20px',
      width: '12rem',
    },
    button: {
      width: '12rem',
    },
    promote: {
      width: '12rem',
      marginRight: theme.custom.REM_SPACES[1],
    },
    cancel: {
      background: theme.custom.PALETTES.STATUS.CANCELED,
      color: theme.custom.PALETTES.OTHER.White,
      borderColor: theme.custom.PALETTES.STATUS.CANCELED,
      '&:hover': {
        background: theme.custom.PALETTES.OTHER.White,
        color: theme.custom.PALETTES.STATUS.CANCELED,
        borderColor: theme.custom.PALETTES.STATUS.CANCELED,
      },
    },
    reactivate: {
      background: theme.custom.PALETTES.STATUS.CANCELED,
      color: theme.custom.PALETTES.OTHER.White,
      borderColor: theme.custom.PALETTES.STATUS.CANCELED,
      '&:hover': {
        background: theme.custom.PALETTES.OTHER.White,
        color: theme.custom.PALETTES.STATUS.CANCELED,
        borderColor: theme.custom.PALETTES.STATUS.CANCELED,
      },
    },
    moreActions: {
      minWidth: '12rem',
    },
  }),
);
