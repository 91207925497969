import dayjs from 'dayjs';

import {
  BrandOrderBrand,
  BrandOrderChannelPartner,
  BrandOrderRetailer,
  BrandOrders,
} from '#/store/api/campaigns/brandOrdersInterfaces';

import { IResource } from '../../../hooks/useGetResource';
import { ICampaignOverviewGeneral } from '../../../interfaces/campaigns/campaigns';
import { ICampaignGeneralInternal, IEditGeneralOverview } from '../../../interfaces/forms/campaigns';
import { baseApi } from '../../base';
import {
  Campaigns,
  IBrandOrdersGeneralInformation,
  ICampaignBrand,
  ICampaignBrandCalendar,
  ICampaignChannelPartner,
  ICampaignChannelPartnerCalendar,
  ICampaignOverviewLegalTerms,
  ICampaignOverviewMarketingInfo,
  ICampaignRetailer,
  ICampaignRetailerCalendar,
  PostCampaignForm,
  SearchFilters,
} from './campaignsInterfaces';

const campaignApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignsRetailerFilter: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `/retailer/campaign/filters`,
    }),
    getCampaignsBrandFilter: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `/retailer/campaign/brand/filters`,
    }),
    getCampaignsChannelPartnerFilter: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `/retailer/campaign/channel-partner/filters`,
    }),

    getCampaignsRetailer: builder.query<
      Campaigns<ICampaignRetailer>,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `/retailer/campaign?${searchParams}`;
      },
      providesTags: ['CAMPAIGNS'],
    }),

    getCampaignsChannelPartner: builder.query<
      Campaigns<ICampaignChannelPartner>,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `/retailer/campaign/channel-partner?${searchParams}`;
      },
      providesTags: ['CAMPAIGNS'],
    }),

    getCampaignsBrand: builder.query<
      Campaigns<ICampaignBrand>,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `/retailer/campaign/brand?${searchParams}`;
      },
      providesTags: ['CAMPAIGNS'],
    }),

    getBrandOrdersRetailerFilter: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `/retailer/instances/filters`,
    }),
    getBrandOrdersRetailer: builder.query<
      BrandOrders<BrandOrderRetailer>,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `/retailer/instances?${searchParams}`;
      },
      providesTags: ['BRAND_ORDERS'],
    }),

    getBrandOrdersChannelPartnerFilter: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `/retailer/instances/channel-partner/filters`,
    }),
    getBrandOrdersChannelPartner: builder.query<
      BrandOrders<BrandOrderChannelPartner>,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `/retailer/instances/channel-partner?${searchParams}`;
      },
      providesTags: ['BRAND_ORDERS'],
    }),

    getBrandOrdersBrandFilter: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `/retailer/instances/brand/filters`,
    }),
    getBrandOrdersBrand: builder.query<
      BrandOrders<BrandOrderBrand>,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `/retailer/instances/brand?${searchParams}`;
      },
      providesTags: ['BRAND_ORDERS'],
    }),

    postCampaign: builder.mutation<void, { body: PostCampaignForm }>({
      query: ({ body }) => ({
        url: `/retailer/campaign`,
        method: 'POST',
        body,
        responseHandler: 'text',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_OVERVIEW_GENERAL', 'CAMPAIGNS']),
    }),
    getCampaignOverviewGeneral: builder.query<ICampaignOverviewGeneral, { resource: IResource }>({
      query: ({ resource }) => `/retailer/${resource.resourceType}/${resource.resourceId}/overview/general`,
      providesTags: ['CAMPAIGN_OVERVIEW_GENERAL'],
    }),
    putCampaignOverviewGeneral: builder.mutation<void, { resource: IResource; body: IEditGeneralOverview }>({
      query: ({ resource, body }) => ({
        url: `/retailer/${resource.resourceType}/${resource.resourceId}/overview/general`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_OVERVIEW_GENERAL', 'CAMPAIGN_HEADER', 'CAMPAIGNS']),
    }),

    getCampaignOverviewInternalSection: builder.query<ICampaignGeneralInternal, { resource: IResource }>({
      query: ({ resource }) => `/retailer/${resource.resourceType}/${resource.resourceId}/overview/internal-section`,
      providesTags: ['CAMPAIGN_OVERVIEW_INTERNAL_SECTION'],
    }),
    putCampaignOverviewInternalSection: builder.mutation<
      void,
      {
        resource: IResource;
        body: ICampaignGeneralInternal;
      }
    >({
      query: ({ resource, body }) => ({
        url: `/retailer/${resource.resourceType}/${resource.resourceId}/overview/internal-section`,
        method: 'PUT',
        body: {
          ...body,
          campaignGoal: body.goal,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_OVERVIEW_INTERNAL_SECTION']),
    }),

    getCampaignOverviewMarketingSection: builder.query<ICampaignOverviewMarketingInfo, { resource: IResource }>({
      query: ({ resource }) => `/retailer/${resource.resourceType}/${resource.resourceId}/overview/marketing-info`,
      providesTags: ['CAMPAIGN_OVERVIEW_MARKETING_INFO'],
    }),
    putCampaignOverviewMarketingSection: builder.mutation<
      void,
      { resource: IResource; body: ICampaignOverviewMarketingInfo }
    >({
      query: ({ resource, body }) => {
        const formattedBody = {
          promotionalFileId: body?.promotionalFileId || null,
          additionalFileIds: body?.additionalFilesId || [],
          summary: body?.summary || null,
          thumbnailId: body?.thumbnailId || null,
        };
        return {
          url: `/retailer/${resource.resourceType}/${resource.resourceId}/overview/marketing-info`,
          method: 'PUT',
          body: formattedBody,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_OVERVIEW_MARKETING_INFO']),
    }),

    getCampaignOverviewLegalSection: builder.query<
      ICampaignOverviewLegalTerms,
      {
        campaignId: string | undefined;
      }
    >({
      query: ({ campaignId }) => `/retailer/campaign/${campaignId}/overview/legal-terms`,
      providesTags: ['CAMPAIGN_OVERVIEW_LEGAL_INFO'],
    }),
    putCampaignOverviewLegalSection: builder.mutation<
      void,
      {
        resource: IResource;
        body: ICampaignOverviewLegalTerms;
      }
    >({
      query: ({ resource, body }) => {
        const formattedBody = {
          termsAndConditionId: body?.termAndConditionsFileId || null,
          additionalTerms: body?.additionalTerms || null,
          customTermsAndConditionId: body?.customTermAndConditionsFileId || null,
        };
        return {
          url: `/retailer/${resource.resourceType}/${resource.resourceId}/overview/legal-terms`,
          method: 'PUT',
          body: formattedBody,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['CAMPAIGN_OVERVIEW_LEGAL_INFO']),
    }),

    getRetailerCampaignCalendar: builder.query<
      ICampaignRetailerCalendar[],
      {
        startDate?: string;
        endDate?: string;
      }
    >({
      query: ({ startDate = `${dayjs().year()}-01-01`, endDate = `${dayjs().year()}-12-31` }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('startDate', startDate);
        searchParams.set('endDate', endDate);
        return `retailer/campaign/calendar?${searchParams}`;
      },
    }),
    getBrandCampaignCalendar: builder.query<ICampaignBrandCalendar[], { startDate?: string; endDate?: string }>({
      query: ({ startDate = `${dayjs().year()}-01-01`, endDate = `${dayjs().year()}-12-31` }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('startDate', startDate);
        searchParams.set('endDate', endDate);
        return `retailer/campaign/brand/calendar?${searchParams}`;
      },
    }),
    getChannelPartnerCampaignCalendar: builder.query<
      ICampaignChannelPartnerCalendar[],
      { startDate?: string; endDate?: string }
    >({
      query: ({ startDate = `${dayjs().year()}-01-01`, endDate = `${dayjs().year()}-12-31` }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('startDate', startDate);
        searchParams.set('endDate', endDate);
        return `retailer/campaign/channel-partner/calendar?${searchParams}`;
      },
    }),

    getCampaignHeader: builder.query<
      { campaignName: string; status: string; campaignId: string; actions: string[] },
      { resource: IResource }
    >({
      query: ({ resource }) => `/retailer/${resource.resourceType}/${resource.resourceId}/header`,
      providesTags: ['CAMPAIGN_HEADER'],
    }),
    getBrandOrderGeneralInformation: builder.query<
      IBrandOrdersGeneralInformation,
      {
        campaignId: string | undefined;
      }
    >({
      query: ({ campaignId }) => `/retailer/campaign/brand-orders/${campaignId}`,
    }),
    getRetailerCalendar: builder.query<ICampaignRetailer, { campaignId: string }>({
      query: ({ campaignId }) => `/retailer/campaign/${campaignId}/calendar`,
      providesTags: [
        'CAMPAIGN_OVERVIEW_MARKETING_INFO',
        'CAMPAIGN_OVERVIEW_GENERAL',
        'CAMPAIGN_OVERVIEW_INTERNAL_SECTION',
      ],
    }),
    getBrandCalendar: builder.query<ICampaignBrand, { campaignId: string }>({
      query: ({ campaignId }) => `/retailer/campaign/brand/${campaignId}/calendar`,
    }),
    getChannelPartnerCalendar: builder.query<ICampaignChannelPartner, { campaignId: string }>({
      query: ({ campaignId }) => `/retailer/campaign/channel-partner/${campaignId}/calendar`,
    }),
    postCampaignCancel: builder.mutation<void, { resource: IResource }>({
      query: ({ resource }) => ({
        url: `/retailer/${resource.resourceType}/${resource.resourceId}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['CAMPAIGN_OVERVIEW_GENERAL', 'CAMPAIGNS', 'CAMPAIGN_HEADER', 'INSTANCE_HEADER'],
    }),
    postCampaignInstanceReactivate: builder.mutation<void, { resource: IResource; brandId: string }>({
      query: ({ resource, brandId }) => ({
        url: `/retailer/${resource.resourceType}/${resource.resourceId}/${brandId}/reactivate`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['CAMPAIGN_OVERVIEW_GENERAL', 'CAMPAIGNS', 'CAMPAIGN_HEADER', 'INSTANCE_HEADER'],
    }),
    postCampaignClose: builder.mutation<void, { resource: IResource }>({
      query: ({ resource }) => ({
        url: `/retailer/${resource.resourceType}/${resource.resourceId}/close`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['CAMPAIGN_OVERVIEW_GENERAL', 'CAMPAIGNS', 'CAMPAIGN_HEADER', 'INSTANCE_HEADER'],
    }),
  }),
});

export const {
  useGetCampaignOverviewGeneralQuery,
  usePutCampaignOverviewGeneralMutation,
  useGetCampaignOverviewInternalSectionQuery,
  usePutCampaignOverviewInternalSectionMutation,
  useGetCampaignOverviewMarketingSectionQuery,
  usePutCampaignOverviewMarketingSectionMutation,
  useGetCampaignOverviewLegalSectionQuery,
  usePutCampaignOverviewLegalSectionMutation,
  useGetRetailerCampaignCalendarQuery,
  useGetBrandCampaignCalendarQuery,
  useGetChannelPartnerCampaignCalendarQuery,
  useGetCampaignHeaderQuery,
  useGetBrandOrderGeneralInformationQuery,
  useGetRetailerCalendarQuery,
  useGetBrandCalendarQuery,
  useGetChannelPartnerCalendarQuery,
  useGetCampaignsRetailerQuery,
  useGetCampaignsChannelPartnerQuery,
  useGetCampaignsBrandQuery,
  useGetCampaignsRetailerFilterQuery,
  useGetCampaignsBrandFilterQuery,
  useGetCampaignsChannelPartnerFilterQuery,
  usePostCampaignMutation,
  usePostCampaignCancelMutation,
  usePostCampaignInstanceReactivateMutation,
  usePostCampaignCloseMutation,
  useGetBrandOrdersRetailerFilterQuery,
  useGetBrandOrdersRetailerQuery,
  useGetBrandOrdersChannelPartnerFilterQuery,
  useGetBrandOrdersChannelPartnerQuery,
  useGetBrandOrdersBrandFilterQuery,
  useGetBrandOrdersBrandQuery,
} = campaignApi;

export { campaignApi };
