import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { useSnackbarSuccess } from '#/components/common/snackbar/useSnackbarSuccess';

import { usePutBrandOrderDetailsMutation } from '#/store/api/campaigns/instances';

type CampaignBrandOrderFormType = {
  purchaseOrderNumber: string;
  billingType: string;
  billingEffectiveDate: string;
  categoryManagerId: string;
  manager: { label: string; value: string };
};

export const useEditCampaignBrandOrder = () => {
  const { instanceId } = useParams();
  const { setSuccess } = useSnackbarSuccess();
  const [submitCampaignDynamicRibbon, { isLoading }] = usePutBrandOrderDetailsMutation();

  const schema = yup.object().shape({
    purchaseOrderNumber: yup.string().nullable(),
    billingType: yup.string().nullable(),
    billingEffectiveDate: yup.string().nullable(),
    categoryManagerId: yup.string().nullable(),
  });
  const {
    register,
    reset,
    setValue,
    watch,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<CampaignBrandOrderFormType>({
    resolver: yupResolver(schema),
  });
  const { setError } = useSnackbarError();
  const onSubmit = handleSubmit(async (formData: CampaignBrandOrderFormType) => {
    const { purchaseOrderNumber, billingType, billingEffectiveDate, categoryManagerId } = formData;

    const requestData = {
      purchaseOrderNumber,
      billingType,
      billingEffectiveDate,
      categoryManagerId,
    };

    submitCampaignDynamicRibbon({ instanceId, body: requestData })
      .unwrap()
      .then(() => {
        setSuccess('Your changes have been saved');
      })
      .catch(() => {
        setError({ status: 500, message: 'Something went wrong, please try again' });
      });
  });
  return {
    register,
    setValue,
    handleSubmit,
    errors,
    watch,
    onSubmit,
    reset,
    editLoading: isLoading,
    trigger,
    getValues,
  };
};
