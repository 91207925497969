import { ITableColumnsProps, TableCellTypes, TableColumnAlign } from '#/interfaces/table/table';

import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';
import { CampaignInstanceRetailer } from '#/store/api/campaigns/instancesInterfaces';

import { currencyFormatter } from '#/utils/textFormatters';
import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

const getCampaignInstancesRetailerTableColumns = (
  columNames: SearchFilters[] = [],
): ITableColumnsProps<CampaignInstanceRetailer>[] => {
  const sortableColumns = columNames.map((el) => el.columnName);

  return [
    {
      fieldName: 'instanceId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Order ID',
      linkable: true,
      baseUrl: `/instances/overview`,
      actionElementIdentifier: 'instanceId',
      sortable: sortableColumns.includes('instanceId'),
    },
    {
      fieldName: 'brandName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Brand Name',
      sortable: sortableColumns.includes('brandName'),
    },
    {
      fieldName: 'agency',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Agency',
      textFormatter: (el: string | number) => {
        if (typeof el === 'number') return el.toString();
        return el.replace(',', ', ');
      },
      sortable: sortableColumns.includes('agency'),
    },
    {
      fieldName: 'contact',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Key contact',
      textFormatter: (el: string | number) => {
        if (typeof el === 'number') return el.toString();
        return el.replace(',', ', ');
      },
      sortable: sortableColumns.includes('contact'),
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      textFormatter: convertAllCapsUnderscoreStrings,
      customHead: 'Status',
      sortable: sortableColumns.includes('status'),
    },
    {
      fieldName: 'tier',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Tier',
      sortable: sortableColumns.includes('tier'),
    },
    {
      fieldName: 'budget',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Budget',
      textFormatter: currencyFormatter,
      itemClass: 'text-right',
      sortable: sortableColumns.includes('budget'),
    },
    {
      fieldName: 'bookedTactic',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Booked Tactics',
      sortable: sortableColumns.includes('bookedTactic'),
    },
    {
      fieldName: 'pendingAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Pending Assets',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('pendingAssets'),
    },
    {
      fieldName: 'inReviewAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'In Review',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('inReviewAssets'),
    },
    {
      fieldName: 'approvedAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Approved',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('approvedAssets'),
    },
    {
      fieldName: 'totalAssets',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Total',
      textFormatter: (text) => text?.toString() || '0',
      sortable: sortableColumns.includes('totalAssets'),
    },
  ];
};

export default getCampaignInstancesRetailerTableColumns;
