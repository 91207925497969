import { Box, FormControl } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import NewButton from '#/components/common/button/NewButton';
import DatePicker from '#/components/common/inputs/DatePicker/DatePicker';
import RadioGroup from '#/components/common/inputs/RadioGroup/RadioGroup';
import TextField from '#/components/common/inputs/TextField/TextField';
import Select from '#/components/common/inputs/Select/Select';
import BrandOrderModalItem from '#/components/shared/BrandOrderModalItem/BrandOrderModalItem';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { useEditCampaignBrandOrder } from '#/hooks/forms/campaigns/instances/useEditCampaignBrandOrder';

import {
  useGetSavedBrandOrdersAfterQuery,
  useGetSavedBrandOrdersBillingEffectiveDateQuery,
  useGetSavedBrandOrdersQuery,
} from '#/store/api/stores/stores';

import { useGetCategoryManagersRoleQuery } from '#/store/api/users/users';

import PermissionWrapper from '#/utils/PermissionWrapper';
import UserTypeWrapper from '#/utils/UserTypeWrapper';
import { currencyFormatter } from '#/utils/textFormatters';
import { convertAllCapsUnderscoreStrings } from '#/utils/utils';

import Card from '../../../common/card/card';
import SingleDownloadUpload from '../../../common/downloadUpload/SingleDownloadUpload';
import Loader from '../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useCampaignDynamicRibbonModal } from '../../../features/campaigns/instances/overview/CampaignDynamicRibbonModal.styles';
import classNames from 'classnames';

const CampaignBrandOrderPage = () => {
  const { instanceId } = useParams();
  const { data: retailerData, isLoading: loadingRetailerDate } = useGetSavedBrandOrdersAfterQuery({
    instanceId: instanceId,
  });

  const { onSubmit, register, setValue, editLoading, getValues } = useEditCampaignBrandOrder();
  const { data, isLoading, isSuccess } = useGetSavedBrandOrdersQuery({ instanceId: instanceId });
  const {
    data: billingEffectiveDate,
    isLoading: isBillingEffectiveDateLoading,
    isSuccess: isBillingEffectiveDateSuccess,
  } = useGetSavedBrandOrdersBillingEffectiveDateQuery({ instanceId: instanceId });

  const roleName = 'Category Manager';
  const encodedRole = encodeURIComponent(roleName);
  const {
    data: allUsers,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useGetCategoryManagersRoleQuery({ role: encodedRole });

  const [billingTypeSelect, setBillingTypeSelect] = useState('');

  useEffect(() => {
    if (isSuccess && isBillingEffectiveDateSuccess) {
      setValue('billingType', data?.billingType);
      setValue('purchaseOrderNumber', data?.purchaseOrderNumber);
      setValue('billingEffectiveDate', billingEffectiveDate?.billingEffectiveDate || '');
      setBillingTypeSelect(data?.billingType || '');
    }
  }, [data, isSuccess, billingEffectiveDate?.billingEffectiveDate, isBillingEffectiveDateSuccess, setValue]);

  const classes = useCampaignDynamicRibbonModal();

  if (isLoading || loadingRetailerDate || isBillingEffectiveDateLoading || isUsersLoading) {
    return <Loader />;
  }

  if (isUsersError) {
    return <Box>Error loading users</Box>;
  }

  if (!data) {
    return <Box>There is no brand order saved</Box>;
  }

  const {
    additionalTerms,
    agencyLegalName,
    agencyName,
    agencyVendorId,
    billingType,
    brandLegalName,
    brandName,
    brandVendorId,
    contractAmount,
    contractSignedDate,
    contractSignerEmail,
    contractSignerName,
    contractingPartyName,
    contractingPartyVendorId,
    customTermsAndConditionsId,
    legalTermsId,
    marketingDateEnd,
    marketingDateStart,
    primaryContactName,
    purchaseOrderNumber,
    tierName,
    categoryManagerId,
  } = data;

  const formatContractSignedDate = dayjs(contractSignedDate).format('YYYY-MM-DD HH:mm:ss');
  const formatRetailerSignedDate =
    retailerData?.retailerSignedDate !== undefined &&
    dayjs(retailerData?.retailerSignedDate).format('YYYY-MM-DD HH:mm:ss');
  return (
    <form onSubmit={onSubmit}>
      <Card customClass={classes.brandCustomPaddingCard}>
        <TypographyWithFontFamily variant="H3_Base">Brand Order Details</TypographyWithFontFamily>
        <br />
        <Box className={classes.dflex}>
          {/*left */}
          <Box className={classes.width50}>
            <BrandOrderModalItem itemTitle="Tier Name" itemValue={tierName} />
            <PermissionWrapper
              requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_BRAND_INSTANCES, USER_PERMISSIONS.CAMPAIGN_INSTANCES]}
            >
              <BrandOrderModalItem itemTitle="Tier Budget" itemValue={currencyFormatter(contractAmount)} />
            </PermissionWrapper>
            <BrandOrderModalItem itemTitle="Campaign Start Date" itemValue={marketingDateStart} />
            <BrandOrderModalItem itemTitle="Campaign End Date" itemValue={marketingDateEnd} spacingBottom />
            <BrandOrderModalItem itemTitle="Brand" itemValue={brandName} />
            <BrandOrderModalItem itemTitle="Brand Legal Name" itemValue={brandLegalName} />
            <BrandOrderModalItem itemTitle="Brand Vendor ID" itemValue={brandVendorId} spacingBottom />
            <BrandOrderModalItem itemTitle="Agency" itemValue={agencyName} />
            <BrandOrderModalItem itemTitle="Agency Legal Name" itemValue={agencyLegalName} />
            <BrandOrderModalItem itemTitle="Agency Vendor ID" itemValue={agencyVendorId} />
            <BrandOrderModalItem itemTitle="Primary Contact" itemValue={primaryContactName} />
            <Box className={classNames(classes.flexCenter)}>
              <TypographyWithFontFamily className={classNames(classes.boldLabel)}>
                Category manager
              </TypographyWithFontFamily>
              {allUsers && allUsers.length > 0 ? (
                <Select
                  className={classNames(classes.selectWidth, classes.selectTightSpacing)}
                  dataCy="categoryManagers"
                  defaultValue={categoryManagerId}
                  label={undefined}
                  options={allUsers?.map((user: { userId: string; firstName: string; lastName: string }) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.userId,
                  }))}
                  register={register('categoryManagerId')}
                  useRegister
                />
              ) : (
                <Box>No users found</Box>
              )}
            </Box>
          </Box>

          {/* right */}
          <Box className={classes.lh1}>
            <BrandOrderModalItem itemTitle="Contracting Party Name" itemValue={contractingPartyName} />
            <BrandOrderModalItem itemTitle="Contracting Party Vendor ID" itemValue={contractingPartyVendorId} />
            <BrandOrderModalItem itemTitle="Contract Signer" itemValue={contractSignerName} />
            <BrandOrderModalItem itemTitle="Contract Signer Email" itemValue={contractSignerEmail} spacingBottom />
            <BrandOrderModalItem itemTitle="Contract Signer Date" itemValue={formatContractSignedDate} />
            <BrandOrderModalItem
              itemTitle="Retailer Signer Date"
              itemValue={formatRetailerSignedDate || ''}
              spacingBottom
            />
            <PermissionWrapper
              requiredPermissions={[
                USER_PERMISSIONS.CAMPAIGN_BRAND_INSTANCES,
                USER_PERMISSIONS.CAMPAIGN_CHANNEL_PARTNER_INSTANCES,
              ]}
            >
              <BrandOrderModalItem
                itemTitle="Billing Type"
                itemValue={
                  billingType === null ? 'No billing type selected' : convertAllCapsUnderscoreStrings(billingType)
                }
              />
            </PermissionWrapper>
            <UserTypeWrapper
              retailer={
                <Box className={classes.flexCenter}>
                  <TypographyWithFontFamily cName={classes.boldLabel}>Billing Effective Date</TypographyWithFontFamily>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      dataCy="billing-effective-date"
                      onChange={(value) => {
                        setValue('billingEffectiveDate', dayjs(value).format('YYYY-MM-DD'), {
                          shouldValidate: true,
                        });
                      }}
                      value={getValues('billingEffectiveDate')}
                    />
                  </LocalizationProvider>
                </Box>
              }
            />
            <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS_WRITE]}>
              <Box className={classes.flexCenter}>
                <TypographyWithFontFamily cName={classes.boldLabel}>Billing Type</TypographyWithFontFamily>
                <TypographyWithFontFamily cName={classes.label}>
                  <FormControl disabled={billingTypeSelect === null}>
                    <RadioGroup
                      dataCy="billingType"
                      label={undefined}
                      onChange={(e) => setBillingTypeSelect(e.target.value)}
                      options={[
                        { label: 'BillBack', value: 'BILL_BACK' },
                        { label: 'Direct Billing', value: 'DIRECT_BILLING' },
                      ]}
                      register={register}
                      registerParam="billingType"
                      value={billingTypeSelect}
                    />
                  </FormControl>
                </TypographyWithFontFamily>
              </Box>
            </PermissionWrapper>

            <PermissionWrapper
              requiredPermissions={[
                USER_PERMISSIONS.CAMPAIGN_BRAND_INSTANCES,
                USER_PERMISSIONS.CAMPAIGN_CHANNEL_PARTNER_INSTANCES,
              ]}
            >
              <BrandOrderModalItem itemTitle="PO #" itemValue={purchaseOrderNumber} spacingBottom />
            </PermissionWrapper>

            <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS_WRITE]}>
              <Box className={classes.flexCenter}>
                <TypographyWithFontFamily cName={classes.boldLabel}>PO#</TypographyWithFontFamily>
                <TypographyWithFontFamily cName={classes.label}>
                  <TextField
                    dataCy="purchaseOrderNumber"
                    defaultValue={purchaseOrderNumber}
                    formHooks={{
                      register: register('purchaseOrderNumber'),
                      setValue: (value) => setValue('purchaseOrderNumber', value),
                    }}
                    label={undefined}
                    value={purchaseOrderNumber}
                  />
                </TypographyWithFontFamily>
              </Box>
            </PermissionWrapper>
          </Box>
        </Box>

        <Box className={classes.dflex}>
          {/*left */}
          <Box className={classes.bottomColumnWidth}>
            <Box>
              <TypographyWithFontFamily cName={classes.boldLabel}>Legal Terms</TypographyWithFontFamily>
              <SingleDownloadUpload fileId={legalTermsId} />
            </Box>
            <Box>
              <TypographyWithFontFamily cName={classes.boldLabel}>Custom Legal Terms</TypographyWithFontFamily>
              <SingleDownloadUpload fileId={customTermsAndConditionsId} />
            </Box>
          </Box>
          {/* right*/}
          <Box>
            <TypographyWithFontFamily cName={classes.boldLabel}>Additional Terms</TypographyWithFontFamily>
            <TypographyWithFontFamily cName={classes.label}>{additionalTerms}</TypographyWithFontFamily>
          </Box>
        </Box>
        <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS_WRITE]}>
          <Box className={classes.brandButton}>
            <NewButton
              disabled={editLoading || isLoading || isBillingEffectiveDateLoading}
              loading={editLoading}
              text="Save"
              type="submit"
            />
          </Box>
        </PermissionWrapper>
      </Card>
    </form>
  );
};

export default CampaignBrandOrderPage;
